<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>{{ title }}</h1>
        <div v-html="body"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Page from "@/models/Page";
export default {
    name: "About",
    data() {
      return {
        title: '',
        body: ''
      }
    },
    mounted() {
      this.fetchPage()
    },
    methods: {
      fetchPage() {
        Page.where('slug', 'about').get().then(ApiResponse => {
          let response = ApiResponse.getData()
          if (response[0] !== undefined) {
            this.body = response[0].body
            this.title = response[0].title
          }
        })
      }
    }
}
</script>

<style scoped>

</style>
